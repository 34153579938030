import React, { useState } from 'react'
import Footer from '../../components/footer'
import '../../styles/solution/game.less'
import SEO from '../../components/SEO'
import { ConsultationPopButton } from '../../components/form-biz'
import { Tab } from '../../components/ui'
import Drop from '../../components/drop'
import '../../styles/solution/blockchain.less'
import { ContactPopButton } from '../../components/form-biz'
import { SolutionTopBanner, ProductBlog } from '../../components/block'
import { graphql } from 'gatsby'
const loadText = require('src/utils').loadText
import LeftRightCheckLayout from '../../components/block/leftAndRightCheckLayout'
import { lang } from '../../utils'
import { Button } from '../../components/ui'
// import { T } from "antd/lib/upload/utils"port
import { TopBanner } from '../../pages-en/lp/intel-whitepaper'
import { MapContainer } from '../products/bare-metal-cloud'
const url_data = loadText('url')
const navigate = (url) => window.open(url)
const data = loadText('blockchain')
const BottomBanner = () => {
  return (
    <div className="media-bottomBanner-wrapper">
      <div className="media-bottomBanner">
        <div className="media-bottomBanner-bg full-bg">
          <div className="media-bottomBanner-content page-middle">
            <h3 className="font-36 t-color semi-bold title">
              Intel Whitepaper
            </h3>
            <ConsultationPopButton source="media">
              {data.bottomBanner.buttonText}
            </ConsultationPopButton>
          </div>
        </div>
      </div>
    </div>
  )
}
const B1 = () => {
  return (
    <div className="blockchain-b1">
      <h2>{data.b1.title}</h2>
      {data.b1.content.map(({ name, feature, description }, i) => (
        <div className="blockchain-b1-item">
          <div className="left">
            <img src={`/solution/blockchain/b1-${i + 1}.svg`} />
            <div className="name">{name}</div>
          </div>
          <div className="right">
            <div className="feature">{feature}</div>
            <div className="description">{description}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

const B2 = () => {
  return (
    <div className="blockchain-b2">
      <h2 className="blockchain-b2-title">{data.b2.title}</h2>
      <div className="blockchain-b2-subtitle">{data.b2.subtitle}</div>
      <div className="blockchain-b2-bg">
        <LeftRightCheckLayout
          bg="/solution/blockchain/b2-bg.svg"
          content={data.b2.content.map((d, i) => ({
            ...d,
            img: `/solution/blockchain/${i + 1}pic.png`,
            imgM: `/solution/blockchain/${i + 1}pic-m.png`,
          }))}
        />
      </div>
    </div>
  )
}
const B3 = () => {
  return (
    <div className="blockchain-b3">
      <div className="blockchain-b3-bg">
        <div className="blockchain-b3-content">
          <div>
            <img src="/solution/blockchain/console.png" alt="console" />
          </div>
          <div>
            <h2 className="title white">{data.b3.title}</h2>
            <div className="description">{data.b3.description}</div>
            <Button
              className="white"
              size="l"
              width={400}
              onClick={() => {
                window.open(
                  `https://console.zenlayer.com?source=website-blockchain&referral=${encodeURIComponent(
                    `${location.origin}/${location.pathname}`
                  )}`
                )
              }}
            >
              {data.b3.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
const B4 = () => {
  return (
    <div className="blockchain-b4">
      <div className="blockchain-b4-bg">
        <div className="blockchain-b4-content">
          <div className="left">
            <h2 className="title">{data.b4.title}</h2>
            <div className="description-wrap">
              {data.b4.descriptions.map((d) => (
                <div className="description">{d}</div>
              ))}
            </div>
            <Button
              size="l"
              width={400}
              onClick={() => {
                window.open('https://solana.foundation/server-program')
              }}
            >
              {data.b4.buttonText}
            </Button>
          </div>
          <div>
            <img src="/solution/blockchain/solana.png" alt="solana" />
          </div>
        </div>
      </div>
    </div>
  )
}
const B5 = () => {
  return (
    <div className="blockchain-b5">
      <div className="blockchain-b5-bg">
        <div className="blockchain-b5-content">
          <h2 className="title">{data.b5.title}</h2>
          <ContactPopButton buttonWidth={400} source="blockchain">
            {data.b5.buttonText}
          </ContactPopButton>
        </div>
      </div>
    </div>
  )
}

export default function Home({ data: graphqlData }) {
  const { allStrapiPosts } = graphqlData
  return (
    <div className="blockchain">
      <SEO {...data.seo} featuredImage="/featuredImage/blockchain.jpg" />
      <SolutionTopBanner
        {...data.topBanner}
        bgColor="linear-gradient(180deg, #F2FDF7 0%, #E0F9EC 100%)"
        source="blockchain"
        backgroundAnimated={true}
        extraButton={
          <Button
            className={'white'}
            size="l"
            onClick={() => {
              window.open(
                `https://console.zenlayer.com?source=website-blockchain&referral=${encodeURIComponent(
                  `${location.origin}/${location.pathname}`
                )}`
              )
            }}
          >
            {data.topBanner.buttonText2}
          </Button>
        }
        pic={{
          src: '/solution/blockchain/topBanner-pic.png',
          style: { position: 'absolute', bottom: '160px', right: '109px' },
        }}
        picM={{
          src: '/solution/blockchain/topBanner-pic.png',
          style: {
            position: 'absolute',
            bottom: '108px',
            right: '80px',
            width: '468px',
          },
        }}
        bannerPic={{
          src: '/solution/blockchain/topBanner-bg-m.svg',
          style: {
            backgroundPosition: 'top center',
          },
        }}
        bgPic="/solution/blockchain/topBanner-bg.svg"
      />
      <B1 />
      <B2 />
      <B3 />
      <MapContainer
        title={data.map.title}
        description={data.map.description}
        // defaultSelected={[]}
      />
      {/* <B4 /> */}
      <TopBanner isBanner={true} />

      <ProductBlog
        title={data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <B5 />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        tags: { elemMatch: { name: { eq: "Blockchain" } } }
        author: {}
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
  }
`
