import React from "react"
import "./index.less"
const Index = ({
  content,
  bg,
}: {
  content: {
    name: string
    img: string
    imgM: string
    content: string[]
  }[]
  bg?: string
}) => (
  <div
    className="lr-itemContainer"
    // style={
    //   bg
    //     ? {
    //         backgroundImage: `url(${bg})`,
    //       }
    //     : {}
    // }
  >
    {content.map(({ name, content, img, imgM }, i) => (
      <div className="lr-item mt-56 clearfix">
        <div className="imgContainer">
          <img src={img} className="only-desktop" alt={name} />
          <img src={imgM} className="only-mobile" alt={name} />
          <div
            className="only-mobile name font-28 semi-bold"
            dangerouslySetInnerHTML={{ __html: name }}
          ></div>
        </div>
        <div className="textContainer">
          <h3 className="only-desktop font-28 semi-bold t-color mt-24">
            {name}
          </h3>
          <ul className="mt-24">
            {content.map(c => (
              <li>
                <div>{c}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    ))}
  </div>
)

export default Index
