import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import SEO from '../../components/SEO'
import '../../styles/intel-whitepaper.less'
import { EbookPageForm } from '../../components/form-biz'
// import { lang } from '../../utils'
import { Button } from '../../components/ui'
import { navigate } from 'gatsby'
const loadText = require('src/utils').loadText
// const data = loadText('intel-whitepaper')

export const TopBanner = ({ isBanner = false }: { isBanner?: boolean }) => (
  <div className={`intel-whitepaper-topBanner ${isBanner ? 'banner' : ''}`}>
    <div className="intel-whitepaper-topBanner-bg">
      {!isBanner && (
        <div className="headerContainer ">
          <Header logo="/logo-blue.svg" />
        </div>
      )}
      <div className="intel-whitepaper-topBanner-content">
        <div className="left">
          <div className="title">Intel Whitepaper</div>
          <div className="subtitle">
            Maximize Blockchain Performance in Emerging Markets with Zenlayer
            High-Performance Edge Cloud Services
          </div>
          {isBanner && (
            <Button
              size="l"
              width={400}
              onClick={() => {
                navigate('/lp/intel-whitepaper/')
              }}
            >
              Download the white paper
            </Button>
          )}
        </div>
        <div className="right">
          <img
            src="/intel-whitepaper/topBanner-pic.png"
            alt="topBanner-pic"
            className="topBanner-pic"
          />
        </div>
      </div>
    </div>
  </div>
)
const Form = () => (
  <div className="intel-whitepaper-form">
    <div className="intel-whitepaper-form-bg">
      <div className="intel-whitepaper-form-content">
        <div className="left">
          {/* {data.form.content.paragraphs.map((p) => ( */}
          <div>
            Blockchains and fintech companies are in a race to reach users in
            global emerging markets which have the fastest-growing digital
            populations. However, it is not easy to deliver the same
            low-latency, high-quality experiences as those in developed markets.
            And this presents a new set of unique connectivity and
            infrastructure challenges.
          </div>
          <div>
            This comprehensive white paper explores how Zenlayer’s Bare Metal
            Cloud offerings, which leverage the latest Intel technologies, power
            superior performance for blockchain and other high-performance
            services. It also looks at how Zenlayer Cloud Networking helps
            Fintech companies achieve low-latency connectivity across the
            geographies they serve, for optimal customer experience and
            efficient operations.
          </div>
          {/* ))} */}
          <ul>
            <li>Readers will learn the following:</li>
            <li>The benefits of Bare Metal Cloud for blockchains</li>
            <li>
              Infrastructure for the next generation of Web 3.0 and blockchains
            </li>
            <li>
              The need for low-latency cloud networking for high-quality user
              experiences
            </li>
          </ul>
        </div>
        <div className="right">
          <EbookPageForm
            btnText="Download the white paper"
            source="intel/white-paper"
            onSuccess={() => {
              const link = document.createElement('a')
              const file = '/Intel Whitepaper.pdf'
              link.href = `${file}`
              link.download = file
              link.dispatchEvent(new MouseEvent('click'))
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <div className="intel-whitepaper">
      <SEO
        // {...data.seo}
        title="Intel Whitepaper | Zenlayer"
        description=""
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <Form />
      <Footer />
    </div>
  )
}
